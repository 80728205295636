import * as React from "react"
import { Link, graphql, HeadFC } from "gatsby"
import Layout from "../components/Layout"
import 'twin.macro'

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes
  console.log('POSTS', posts)
  if (posts.length === 0) {
    return (
      <p>Bepa</p>
    )
  }

  return (
    <Layout>
      <div className="md:mx-auto md:max-w-3xl">
<ol className='divide-y divide-gray-200 dark:divide-gray-700'>

      {posts.map(post => {
        const title = post.frontmatter.title || post.fields.slug

        return (
          <li className="py-12 px-6" key={post.fields.slug}>
            <article
              className="space-y-6 post-list-item"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <h2 className='text-2xl font-bold'>
                  <Link to={post.fields.slug} itemProp="url">
                    <span itemProp="headline">{title}</span>
                  </Link>
                </h2>
                <small className='text-small font-medium'>{post.frontmatter.date}</small>
              </header>
              <section className='prose'>
                <p
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.description || post.excerpt,
                  }}
                  itemProp="description"
                />
              </section>
            </article>
          </li>
        )
      })}
</ol>
      </div>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Home Page</title>

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY", locale: "sv-se")
          title
          description
        }
      }
    }
  }`